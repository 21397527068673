@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap');

#root {
  padding-top: $calculated-navbar-height-sm;
}

h1.text-center {
  @extend .container;
  text-align: left !important;
  padding-bottom: 0 !important;
}

.product-content {
  background: transparent;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
  .product-detail .container {
    max-width: 80%;
  }
}

.w-40 {
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 33.3333333333%;
  }
}

.product-content h1 {
  @include media-breakpoint-up(md) {
    //@extend .text-truncate;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  //text-transform: uppercase;
  font-weight: 500 !important;
}

.card {
  border: none;
}

.card.bg-light {
  background-color: $gray-800 !important;
  color: $white;
}

.checkout .when-valid {
  background-color: #000;
}

// NAV

#header > .navbar {
  height: auto;
  font-size: 1.3rem;
  background-color: $color-accent !important;
  border-bottom: $color-dark $border-width solid;

  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
    height: $calculated-navbar-height-sm;
  }

  .main-menu {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
  }

  // LOGO
  .navbar-brand {
    padding: 0;

    img {
      max-height: 3rem;
    }
  }

  // MAIN MENU
  .hamburger-box {
    background-color: transparent !important;
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $color-dark;
    }
  }

  .offcanvas-collapse-left {
    top: $calculated-navbar-height;
    height: fit-content;
    @include media-breakpoint-up(md) {
      top: calc(4rem + 4px);
    }
    border-right: $color-dark $border-width solid;
    border-bottom: $color-dark $border-width solid;

    .navbar-nav {
      background-color: $color-accent;
      // font-family: $font-accent;

      .nav-item {
        .nav-link {
          background-color: transparent;
          color: $color-dark;
          padding: 1.5rem;
          font-weight: 800;
          // text-transform: uppercase;
          // font-stretch: 150%;
          // letter-spacing: 3px;

          &:hover,
          &.active {
            text-decoration: underline;
          }
        }
      }
    }
  }

  // CART MENU
  .offcanvas-collapse-right {
    top: $calculated-navbar-height;
    @include media-breakpoint-up(md) {
      top: calc(4rem + 4px);
    }
    background-color: $color-accent;
    border-left: $color-dark $border-width solid;
    color: $color-dark;
  }
}

.product-listing-page .product-listing {
  @extend .p-5;
}

// MAKI

.maki {
  border: none;
  border-radius: 0;

  .maki-img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .card-body {
    @extend .pt-1;
    background: $color-accent;
    color: $color-dark;
    font-size: 1.5rem;

    .card-title {
      flex-direction: row;

      .sub-title {
        display: none;
      }

      .date-container {
        display: none;
      }
      .price {
        color: rgba($color-dark, 0.3);
      }
    }
  }
}

// Product Detail Page

.product-detail {
  @extend .p-5;
  color: $color-dark;

  .detail-page {
    padding: 0;
  }

  .native-slider {
    @extend .px-3;

    .carousel-inner {
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }

    .native-slider-item img {
    }
  }

  .product-name {
    h1 {
      margin-top: 0 !important;
    }

    .product-supertitle {
      display: none;
    }
    .product-title {
      @extend .h1;
      font-weight: 800;
      // text-transform: none;
    }
  }

  .product-header {
    margin-bottom: 0;
  }

  .product-info {
  }

  .price {
    text-align: start;
    color: $color-dark;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .variant-chooser .form-group {
    margin-inline: 0;
  }

  .variant-chooser-select,
  .variant-chooser-quantity {
    display: none !important;
  }

  .variant-chooser-cta {
    padding: 0;
    margin-top: 3rem;
    margin-bottom: 3rem !important;
    // margin-left: 15px;
    // margin-right: 15px;

    button {
      background: transparent;
      color: inherit;
      border: $color-dark 4px solid;
      font-size: 1.5rem !important;
    }
    .fal {
      display: none;
    }
  }

  .product-title {
    text-align: start;
    font-size: $h3-font-size;
    font-weight: 800 !important;
  }
}

// .native-slider {
//   display: flex;
//   align-items: stretch;
//   justify-content: stretch;
//   width: 100%;
//   margin-bottom: 0;

//   .carousel.carousel-scoll-snap {
//     &,
//     & .carousel-inner,
//     & .carousel-item {
//       width: 100%;
//       display: flex;
//       @supports (align-items: stretch) {
//         align-items: stretch;
//       }
//     }
//     & .carousel-item-content {
//       width: 100%;
//       align-items: top;
//       .img-fluid {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         max-width: auto;
//       }
//     }
//   }
// }

// .product-detail-right {
//   display: flex;
//   flex-direction: column;
//   @include media-breakpoint-up(md) {
//     height: 100vh;
//     justify-content: center;
//   }
//   .product-supertitle {
//     display: none;
//   }
//   .product-title {
//     display: block;
//     text-transform: uppercase;
//     margin-bottom: 1rem;
//     letter-spacing: 2px;
//     @include media-breakpoint-down(sm) {
//       margin-top: 60px;
//     }
//   }
//   .product-subtitle {
//     font-size: $h1-font-size;
//     font-weight: 700;
//     font-family: $font-main !important;
//     margin-bottom: 1rem;
//   }
// }

// Footer

.prefooter-wrapper {
  color: $black;
  background-color: $white;

  .prefooter-text {
    padding-left: 0;
    padding-right: 0;
    align-self: center;
    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }
  }

  .prefooter-icon-list {
    align-items: center;
    flex-wrap: wrap;

    .row {
      justify-content: space-around;

      @include media-breakpoint-down(xs) {
        row-gap: 20px;
      }

      div {
        text-align: center;
        @include media-breakpoint-up(xxl) {
          flex: 0 0 16.6666666667% !important;
          max-width: 16.6666666667% !important;
        }
      }
    }

    img {
      max-width: 100px !important;
      max-height: 100px;
      aspect-ratio: 1;
    }
  }
}

#footer {
  color: $color-accent;
  background-color: $black;
  min-height: unset;
  img {
    filter: invert(1);
  }
  a {
    color: $color-accent;
  }
}

// .offcanvas-collapse {
//   @include media-breakpoint-up(md) {
//     // top: 8rem;
//   }
//   //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
//   box-shadow: none;
//   background-color: transparent;
// }
// .offcanvas-collapse-left {
//   bottom: auto;
//   overflow: visible;
// }

.when-valid {
  background: rgba($darkgray, 0.9);
  transition: all 0.3s;
  .modal & {
    background-color: inherit;
  }
}

.sticky-bottom.when-valid {
  &:not(.is-valid) {
    background-color: $gray-800;
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// Loading Spinner
.loading-spinner-content {
  background: transparent !important;
}
#initial-spinner.spinner-boarder.text-dark {
  color: $color-accent !important;
}

// Tickets

// JUMBOTRON
// .header-jumbotron-tickets {
//   // background-color: $dark;
//   // color: $white;
//   background-image: url('./assets/images/ticket_header.jpg');
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: cover;
//   //transform: translateY(-10rem);
//   height: 100vh;
//   @include media-breakpoint-down(sm) {
//     //transform: translateY(-6rem);
//     height: 60vh;
//   }
// }

.ticket-detail-page {
  .blurry-background-image {
    //height: 60vh;
    background-color: transparent;
    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .product-header-image {
    box-shadow: none;
  }

  .blurry-background-image:before {
    // filter: blur(0rem);
    // opacity: 1;
    background-position: center center;
    display: none;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    display: none !important;
  }

  h1,
  h2 {
    text-transform: uppercase;
  }
  h1 {
    .sub-title {
      text-transform: none;
      font-family: $font-family-sans-serif;
    }
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    border-bottom: 0.1rem solid rgba($color-dark, 1);

    .super-title {
      font-size: 0.7rem;
      letter-spacing: 1.3px;
      color: rgba(18, 18, 18, 0.75) !important;
      opacity: 1;
    }
  }

  .product-info-bar {
    i {
      color: $color-dark !important;
    }
    background-color: transparent;
    //text-transform: uppercase;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid $color-dark;
    box-shadow: none;
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }
}

// TICKETS

#ticket-listing,
.ticket-listing {
  .list-page {
    //padding-top: 4vh;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }

  .nigiri.card {
    background-color: transparent;
    border-bottom: 0.1rem solid $color-dark;
    border-radius: 0;

    //border-bottom: 1px solid rgba($white, 0.25);
    .nigiri-content {
      padding: 0rem;
      margin-top: 0.2rem;
      color: $black;
      @include media-breakpoint-up(md) {
        align-items: center;
        padding: 1.25rem;
      }
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h3-font-size;
      font-family: $headings-font-family;
      color: $color-dark;
      font-weight: 900;
      align-items: start !important;
      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      // text-transform: uppercase;
      font-weight: bold;
      // padding-left: 3rem;
      padding-right: 1rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .badge {
      z-index: 2;
      font-size: 0.8em;
    }
    .title {
      font-size: $font-size-base * 1.2;
      margin-bottom: 0;
      color: $primary;
      font-family: $font-family-sans-serif !important;
    }
    .subtitle {
      font-size: $font-size-sm;
      margin-bottom: 0;
      color: $gray-600;
      font-weight: normal;
      text-transform: none;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
        margin-bottom: 0.5rem;
      }
    }
    .location {
      font-weight: 800 !important;
      font-size: $h3-font-size !important;
      font-family: $headings-font-family;
      // text-transform: uppercase;
      color: $primary;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
    &:hover {
      background-color: lighten($bgcolor, 1%);
    }
  }
}

.table {
  @extend .table-dark;
  background-color: transparent;
  color: inherit;

  thead {
    border-width: 0;
    th {
      border-top: 0;
      border-bottom-width: 0px;
      font-weight: lighter !important;
    }
  }
}

// links bei haken nicht erkennbar dings
.custom-checkbox.custom-control a {
  text-decoration: underline;
}
